import { useState } from 'react'

import Tile from '../Tile/Tile'
import DataRow from '../DataRow/DataRow'
import { checkWin, getData } from '../../utils/utils'

import './Bingo.css'

export interface ITile {
  text: string | JSX.Element
  checked: boolean
}

const Bingo = () => {
  const [tiles, setTiles] = useState(getData())
  const [win, setWin] = useState(false)

  const handleClick = (index: number) => {
    setTiles((tiles) => {
      const newTiles = [...tiles]
      const tile = {
        text: newTiles[index].text,
        checked: !newTiles[index].checked,
      }
      newTiles[index] = tile

      const isWin = checkWin(newTiles)
      setWin(isWin)

      return newTiles
    })
  }

  const handleReset = () => {
    setTiles(getData())
    setWin(false)
  }

  return (
    <div className="Bingo">
      {win && (
        <div className="Bingo__win" onClick={handleReset}>
          <div>
            <span>BINGO!</span>
            <small>jeszcze raz?</small>
          </div>
        </div>
      )}
      <div className="Bingo__list">
        {tiles.map((tile, index) => (
          <DataRow
            key={index}
            text={`${index + 1}. ${tile.text}`}
            checked={tile.checked}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
      <div className="Bingo__tiles">
        {tiles.map((tile, index) => (
          <Tile
            key={index}
            text={`${index + 1}`}
            checked={tile.checked}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default Bingo
