export const data = [
  'strefa komfortu',
  'będziemy chcieli',
  'sużba',
  'nie rozczulać się nad sobą',
  'użalanie się nad sobą',
  'pobudki',
  'negatywne emocje',
  'pozytywne nastawienie',
  'budujące rozmowy',
  'czasookres (okres czasu)',
  'retoryczne pytania',
  'rzeczywiste życie',
  'osobiste zainteresowanie',
  'kontrolować',
  'pogańskie święta',
  'babilońskie święta',
  'ten świat',
  'zabieganie o wyższe wykształcenie',
  'szkodliwe plotkowanie',
  'korzystanie ze zdolności myślenia',
  'kampania',
  'wideokonferencja',
  'zniechęcenie',
  'samorealizacja',
  'ten system',
  'prawdziwi chrześcijanie',
  'być w prawdzie',
  'samorozwój',
  'wspólnie odczytajmy',
  'pieniążki',
  'rok dwutysięczny (któryś)',
  'zwracać na (coś)',
  'cele duchowe',
  'zadzierzgnąć więź',
  'panowanie nad sobą',
  'serdeczna atmosfera',
  'przechodzenie do porządku',
  'czasy końca',
  'nadmierne myślenie o sobie',
  'schludny ubiór',
  'sprawy duchowe',
  'osobiste dążenia',
  'teren osobisty',
  'zwiększyć udział w służbie',
  'stawić się do dyspozycji',
  'usługiwać',
  'teren z potrzebami',
  'uprościć życie',
  'prowadzić proste życie',
  'służba nieoficjalna',
  'kwalifikacje duchowe',
  'chrześcijańskie przymioty',
  'kochani',
  'literalnie',
  'zdrowie duchowe',
  'czerpać nadzieję',
  'fizyczne zdrowie',
  'strumień czasu',
  'wyjść z inicjatywą',
  'podwójne życie',
  'skorzystać z zebrania',
  'postępy duchowe',
  'styl konwersacyjny',
  'wskazówki',
  'najlepsza wersja samego siebie',
  'pracować nad sobą',
  'wszechświatowa rodzina',
  'egzemplarz Biblii',
  'nieobłudna miłość',
  'zasyłamy',
  'dziedzina duchowa',
  'drodzy',
  'wysilać się',
  'wytężać się',
  'dni ostatnie dni ostatnich',
  'na zoomie',
  'pewna miara władzy',
  'społeczność braterska',
]
