// import data from './data.json'
import { data } from './data'
import { ITile } from '../components/Bingo/Bingo'

const WIDTH = 4
const SIZE = WIDTH * WIDTH

const winMatrix = [
  // horizontal lines:
  [0, 1, 2, 3],
  [4, 5, 6, 7],
  [8, 9, 10, 11],
  [12, 13, 14, 15],
  // vertical lines:
  [0, 4, 8, 12],
  [1, 5, 9, 13],
  [2, 6, 10, 14],
  [3, 7, 11, 15],
  // diagonal lines:
  [0, 5, 10, 15],
  [3, 6, 9, 12],
]

export const checkWin = (tiles: ITile[]) => {
  return winMatrix.reduce((prev, curr) => {
    return (
      prev ||
      curr.reduce((prev2, curr2) => {
        return prev2 && tiles[curr2].checked
      }, true)
    )
  }, false)
}

export const getData = () => {
  const dataClone = [...data]
  // randomly select n elements from array
  const drawn = dataClone.sort(() => 0.5 - Math.random()).slice(0, SIZE)
  return drawn.map((item) => ({ text: item, checked: false }))
}
