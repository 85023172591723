import './DataRow.css'

interface IDataRowProps {
  text: string | JSX.Element
  checked: boolean
  onClick: (e: any) => void
}

const DataRow = ({ text, checked, onClick }: IDataRowProps): JSX.Element => {
  return (
    <div className={`DataRow ${checked ? 'DataRow--checked' : ''}`}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onClick}
          className="form-check-input"
        />
        {text}
      </label>
    </div>
  )
}

export default DataRow
