import Bingo from './components/Bingo/Bingo'

import './App.css'

function App() {
  return (
    <div className="App">
      <div className="App__content">
        <div className="App__title">
          <h1 className="App__header">🎲 Bingo! 🎲</h1>
          <div className="App__subheader">
            Gdy usłyszysz jeden z poniższych zwrotów - zaznacz go. <br />
            Gdy utworzysz linię poziomą, pionową lub ukośną - bingo! <br />
            Odświeżenie strony resetuje grę i losuje nieco inne zwroty.
          </div>
        </div>
        <Bingo />
      </div>
    </div>
  )
}

export default App
