import { MouseEvent } from 'react'

import './Tile.css'

interface ITileProps {
  text: string | JSX.Element
  checked: boolean
  onClick: (e: MouseEvent<HTMLDivElement>) => void
}

const Tile = ({ text, checked, onClick }: ITileProps): JSX.Element => {
  return (
    <div className={`Tile ${checked ? 'Tile--checked' : ''}`} onClick={onClick}>
      {text}
    </div>
  )
}

export default Tile
